

















































































































































































































































































































































































import { Component, Inject, Ref, Vue } from 'vue-property-decorator';

import formatTime from '../../util/format-time';

import ControlButton from '../ControlButton.vue';
import PlaybackScrubber from '../PlaybackScrubber.vue';
import PlaybackScrubberRadial from '../PlaybackScrubberRadial.vue';
import SharePanel from '../SharePanel.vue';
import TrackList from '../TrackList.vue';
import { onKeyDown } from '@/util/keyboard-shortcuts';
import { ImageAnalyzer } from '@/services/image-analyzer';
import { PlayerController, PlayerState } from '@/services/player-controller';
import { SubscriptionManager } from '@/core/utils';
import { config } from '@/config';
import { serviceFormat } from '@/util/service-format';

Component.registerHooks(['metaInfo']);

@Component({
  components: {
    ControlButton,
    PlaybackScrubber,
    PlaybackScrubberRadial,
    TrackList,
    SharePanel,
  },
})
export default class ReverbPlayer extends Vue implements VueComponentLifecycle {
  state: PlayerState = {};
  currentTime = 0;
  audioDuration = 0;
  buffered = 0;

  config = config;
  playerWidth = 0;
  showFloatingControls = false;
  primaryThemeColor = config.theme.defaultColor;

  initiallyLoaded = false;

  trackSize = config.page.pageSize - 1;
  breakSize = config.page.breakSize;

  /* eslint-disable-next-line no-invalid-this */
  trackInterval = [0, this.breakSize];

  @Inject() readonly os!: string;
  @Inject() private imageAnalyzer!: ImageAnalyzer;
  @Inject() private controller!: PlayerController;
  @Ref() private sharePanel!: SharePanel;
  @Ref() private thumbnailImg!: HTMLImageElement;
  private subscriptions!: SubscriptionManager;

  get disableControls(): boolean {
    return !!(this.state.loading || this.state.suspended);
  }

  get playing(): boolean {
    return !!this.state.playing;
  }

  get currentTrack(): string {
    return this.state.story ? this.state.story.id : '';
  }

  get trackTitle(): string {
    return this.state.story ? this.state.story.content.title : '';
  }

  get trackSubtitle(): string {
    return this.state.story ? this.state.story.content.summary : '';
  }

  get thumbnailSrc(): string {
    return this.state.publisher
      ? this.state.publisher.display.thumbnail.small ||
          this.state.publisher.display.thumbnail.default
      : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      ;
  }

  get thumbnailAltTag(): string {
    return this.state.publisher && this.state.publisher.channelName
      ? `${this.state.publisher.channelName} Thumbnail`
      : 'Thumbnail';
  }

  get reverbUrl(): string {
    return this.state.story
      ? `https://play.spokenlayer.com/${this.state.story.publisherId}`
      : '';
  }

  get trackIndex(): number | null {
    if (this.state.story && this.state.trackList) {
      return this.state.trackList.findIndex(
        item => item.story.id === this.state.story!.id
      );
    } else {
      return null;
    }
  }

  get hasPrevious(): boolean {
    return this.trackIndex !== null && this.trackIndex > this.trackInterval[0];
  }

  get hasNext(): boolean {
    return (
      this.trackIndex !== null &&
      this.trackIndex < this.trackInterval[1] - 1 &&
      this.trackIndex < this.state.trackList!.length - 1
    );
  }

  get currentYear(): number {
    const d = new Date();
    return d.getFullYear();
  }

  get channelName(): string {
    return this.state.publisher && this.state.publisher.channelName
      ? this.state.publisher.channelName
      : '';
  }

  get channelDescription(): string {
    return this.state.publisher && this.state.publisher.description
      ? this.state.publisher.description.info
      : '';
  }

  get subscribeUrls(): Dictionary {
    const urls: Dictionary = {};
    if (this.state.publisher && this.state.publisher.urls) {
      const excludedServices = [
        'customSkill',
        'flashBriefing',
        'googleAction',
        'narrativeNews',
        'share',
        'website',
      ];
      let service: string;

      for (service in this.state.publisher.urls) {
        if (
          excludedServices.findIndex(
            excludedService => excludedService === service
          ) < 0
        ) {
          if (this.state.publisher.urls[service]) {
            urls[service] = this.state.publisher.urls[service];
          }
        }
      }
    }

    return urls;
  }

  get subscribeText(): string {
    if (this.hasSmartSpeakerData && this.hasPodcastData) {
      return 'Subscribe or listen on your smart speaker';
    } else if (this.hasSmartSpeakerData) {
      return 'Listen on your smart speaker';
    } else if (this.hasPodcastData) {
      return `Subscribe to ${this.channelName}`;
    } else {
      return '';
    }
  }

  get hasSmartSpeakerData(): boolean {
    return (
      this.googleData.actionLink ||
      this.googleData.briefingLink ||
      this.alexaData.skillLink ||
      this.alexaData.briefingLink
    );
  }

  get hasPodcastData(): boolean {
    return Object.keys(this.subscribeUrls).length > 0;
  }

  get alexaData(): Dictionary {
    const urls = this.state.publisher ? this.state.publisher.urls : {};
    const hasAlexaSkill = urls && urls.customSkill;
    // const hasAlexaBriefing = urls && urls.flashBriefing;

    const alexaBriefingPhrase = 'To Alexa Briefing';
    const alexaInvocationPhrase = hasAlexaSkill
      ? `Alexa, open ${this.getInvocation('alexa') || ''}`
      : `Alexa, what's my Flash Briefing?`;

    const alexaSkillLink = urls && (urls.customSkill || urls.flashBriefing);
    const alexaBriefingLink = urls && urls.flashBriefing;

    return {
      invocationPhrase: alexaInvocationPhrase,
      skillLink: alexaSkillLink,
      briefingPhrase: alexaBriefingPhrase,
      briefingLink: alexaBriefingLink,
    };
  }

  get googleData(): Dictionary {
    const urls = this.state.publisher ? this.state.publisher.urls : {};
    const hasGoogleAction = urls && urls.googleAction;
    // const hasGoogleBriefing = urls && urls.narrativeNews;

    const googleBriefingPhrase = 'To Google Briefing';
    const googleInvocationPhrase = hasGoogleAction
      ? `Hey Google, talk to ${this.getInvocation('google') || ''}`
      : `Hey Google, play the news from ${this.getInvocation('google') || ''}`;

    const googleHasAction = urls && (urls.googleAction || urls.narrativeNews);
    const googleActionLink = urls && urls.googleAction;
    const googleBriefingLink = urls && urls.narrativeNews;

    return {
      invocationPhrase: googleInvocationPhrase,
      actionLink: googleActionLink,
      briefingPhrase: googleBriefingPhrase,
      briefingLink: googleBriefingLink,
      hasAction: googleHasAction,
    };
  }

  ariaLabel(icon?: string): string {
    if ((!icon && !this.playing) || icon === 'play') {
      const label = config.aria.labels[`play${this.os}`];
      return `From ${this.state.publisher?.name}:${this.trackTitle}, ${label}`;
    }

    return config.aria.labels[icon + this.os];
  }

  smartSpeakerLabel(platform: string, invocation: string) {
    return `To listen on ${platform}, say ${invocation}.`;
  }

  formattedService(service: string): string {
    return serviceFormat(service);
  }

  getInvocation(service: 'alexa' | 'google'): string {
    return this.state.publisher && this.state.publisher.invocationNames
      ? this.state.publisher.invocationNames[service]
      : '';
  }

  handlePlay(e: Event): void {
    const button = e.target as HTMLElement;

    if (button.getAttribute('aria-disabled')) {
      return;
    }

    this.playing ? this.controller.pause() : this.controller.play();
  }

  onKey(e: KeyboardEvent): void {
    const button = e.target as HTMLElement;

    if (button.getAttribute('aria-disabled')) {
      return;
    }

    return onKeyDown(e, this.controller, this.state, this.os);
  }

  metaInfo() {
    return {
      meta: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.channelName },
        { name: 'twitter:description', content: this.channelDescription },
        {
          name: 'twitter:image:src',
          content: this.transformedThumbnail('twitter'),
        },
        { property: 'og:title', content: this.channelName },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: this.reverbUrl },
        { property: 'og:description', content: this.channelDescription },
        { property: 'og:site_name', content: this.channelName },
        { property: 'og:image', content: this.transformedThumbnail('fb') },
        { property: 'og:image:alt', content: `${this.channelName} logo` },
        { property: 'fb:app_id', content: config.og.appId },
      ],
    };
  }

  transformedThumbnail(platform: string): string {
    const w = platform === 'fb' ? 1200 : 800;
    const h = platform === 'fb' ? 630 : 418;
    return this.thumbnailSrc.replace(
      /\/image\/upload\/ar_1:1,c_fill\/.*\/d_SpokenLayer_Logo_Verticle_fb9a1b.png/gi,
      `/image/upload/ar_1:1,c_pad,w_${w},h_${h},dpr_auto,f_auto,q_auto,b_auto:border/d_SpokenLayer_Logo_Verticle_fb9a1b.png`
    );
  }

  handleScroll() {
    const progressRing: HTMLElement = document.getElementById('progress-ring')!;
    const boundingRect: DOMRect = progressRing.getBoundingClientRect();

    this.showFloatingControls = boundingRect.height + boundingRect.top < 0;
  }

  handleResize() {
    const player: HTMLElement = document.getElementById('reverb-main')!;
    this.playerWidth = player.scrollWidth;
    this.handleBreaks();
  }

  handleBreaks() {
    if (
      window.innerWidth < config.breakpoint.horizontal ||
      window.innerHeight < config.breakpoint.vertical
    ) {
      this.trackInterval = this.getInterval();
    } else {
      this.trackInterval = [0, this.trackSize];
    }
  }

  getInterval() {
    let beginning;
    let end;

    if (this.trackIndex === null) {
      return this.trackInterval;
    } else if (this.trackIndex === 0) {
      beginning = 0;
      end = this.breakSize;
    } else if (this.trackIndex === this.trackSize - 1) {
      beginning = this.trackSize - this.breakSize;
      end = this.trackSize;
    } else {
      beginning = this.trackIndex - 1;
      end = this.trackIndex;
      let remainingPoints = this.breakSize - 1;

      while (remainingPoints > 0 && end < this.trackSize) {
        end++;
        remainingPoints--;
      }

      beginning -= remainingPoints;
    }

    return [beginning, end];
  }

  formatTime(time: number): string {
    return formatTime(time);
  }

  /* lifecycle */

  created() {
    this.subscriptions = new SubscriptionManager();
    this.state = this.controller.state;
    this.controller.clickTracker.setEventCategory('reverb');
  }

  mounted() {
    const player: HTMLElement = document.getElementById('reverb-main')!;

    window.addEventListener('resize', this.handleResize);
    player.addEventListener('scroll', this.handleScroll);
    setTimeout(this.handleResize, 100);
    setTimeout(this.handleScroll, 100);

    this.subscriptions.watch<Partial<PlayerState>>(
      this.controller.updates$,
      update => {
        this.state = { ...this.state, ...update };

        if (update.audioDuration) {
          this.audioDuration = update.audioDuration;
        }

        if (update.buffered) {
          this.buffered = update.buffered;
        }
      }
    );
    this.subscriptions.watch<number>(
      this.controller.currentTime$,
      currentTime => {
        this.currentTime = currentTime;
      }
    );

    // Get theme color
    this.subscriptions.watch<string>(
      this.imageAnalyzer.observeDominantColor(this.thumbnailImg),
      color => {
        this.primaryThemeColor = color;
        this.initiallyLoaded = true;
      }
    );
  }

  destroyed(): void {
    this.subscriptions.close();

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }
}
